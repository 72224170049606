import {
  addOrderCoupon,
  addOrderCouponFailure,
  addOrderCouponSuccess,
  addPendingOrderItemToCart,
  changeOrderItemCount,
  clickAddItemToCart,
  clickOnMenuItem,
  createNewPendingOrderItem,
  removeOrderCoupon,
  removeOrderItem,
  setOrderComment,
  setPendingOrderItem,
} from './cart.actions';
import { ProbeArgument } from '../createStore';
import {
  AddOrderCouponPayload,
  ClickAddItemToCartPayload,
  ClickOnMenuItemPayload,
  CreateNewPendingOrderItemPayload,
} from './cart.actions.types';
import { couponChargesSelector } from '../selectors/couponChargesSelector';
import { saveStateToSessionStorage, initApp } from '../session/session.actions';
import sha1 from 'js-sha1';
import { Action, createOrderItem, DisplayableMenuItem, extractLocalizedString } from '@wix/restaurants-client-logic';
import { getDisplayableCouponError } from '../../components/MainPage/components/Cart/Cart.helper';
import { supportedDispatchTypesSelector } from '../selectors/supportedDispatchTypesSelector';
import {
  resetLoyaltyPointsToDefaultValue,
  setLoyaltyPointsToRedeem,
  setDispatchType,
} from '../checkout/checkout.actions';
import { cartSummarySelector } from '../selectors/cartSummarySelector';
import { loyaltyProgramSelector } from '../selectors/loyaltyProgramSelector';
import { ResetLoyaltyPointsToDefaultValuePayload, SetDispatchTypePayload } from '../checkout/checkout.actions.types';
import { skipToCheckout } from './cart.probe.utils';

export default function cartProbe({ onAction }: ProbeArgument) {
  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const experiments = await flowAPI.getExperiments();

    if (experiments.enabled("specs.restaurants.olo-client.skipToCheckout")) {
      const state = getState();
      const { restaurant, menu } = state.session;
      const dispatchTime = Date.now();
      const platform = state.platformParams.isMobile ? 'mobileweb' : 'web';

      skipToCheckout({ flowAPI, menu, restaurant, platform, dispatchTime, dispatch });
    }
  });

  onAction(
    createNewPendingOrderItem.toString(),
    async (action: Action<CreateNewPendingOrderItemPayload>, getState, dispatch) => {
      const { itemId } = action.payload;
      const { itemsHash } = getState().session;
      const orderItem = createOrderItem({ itemId, itemsHash });
      dispatch(setPendingOrderItem({ orderItem, isNew: true }));
    },
  );

  onAction(addPendingOrderItemToCart.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(setLoyaltyPointsToRedeem.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(
    resetLoyaltyPointsToDefaultValue.toString(),
    (action: Action<ResetLoyaltyPointsToDefaultValuePayload>, getState, dispatch, { flowAPI }) => {
      dispatch(saveStateToSessionStorage());
      const state = getState();
      const { redeemPoints } = cartSummarySelector(state);
      const { loyaltyRewardId } = loyaltyProgramSelector(state);
      if (flowAPI.biLogger) {
        flowAPI.biLogger.redeemLoyaltyReward({
          rewardId: loyaltyRewardId,
          origin: action.payload.origin,
          loyaltyPoints: redeemPoints,
        });
      }
    },
  );

  onAction(
    clickAddItemToCart.toString(),
    (action: Action<ClickAddItemToCartPayload>, getState, dispatch, { flowAPI }) => {
      const state = getState();
      const orderItem = action.payload.orderItem;
      const restaurant = state.session.restaurant;
      const name = extractLocalizedString(state.session.itemsHash[orderItem.itemId].title, restaurant.locale);
      state.platformParams.viewMode === 'Site' &&
        flowAPI.controllerConfig.wixCodeApi.window.trackEvent('AddToCart', {
          id: orderItem.itemId,
          origin: 'Restaurants',
          name,
          price: orderItem.price / 100,
          currency: restaurant.currency,
          quantity: orderItem.count || 1,
        });
    },
  );

  onAction(changeOrderItemCount.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(clickOnMenuItem.toString(), (action: Action<ClickOnMenuItemPayload>, getState, dispatch, { flowAPI }) => {
    const item: DisplayableMenuItem = action.payload.displayableMenuItem;
    const state = getState();
    const restaurant = state.session.restaurant;
    state.platformParams.viewMode === 'Site' &&
      flowAPI.controllerConfig.wixCodeApi.window.trackEvent('ClickProduct', {
        id: item.id,
        origin: 'Restaurants',
        name: item.displayableTitle,
        price: item.price ? item.price / 100 : undefined,
        currency: restaurant.currency,
      });
  });

  onAction(removeOrderItem.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });
  onAction(removeOrderCoupon.toString(), (action, getState, dispatch, { flowAPI }) => {
    const coupon = getState().cart.coupon;
    if (flowAPI.biLogger) {
      flowAPI.biLogger.cartPromoCodeRemove({
        promoCode: coupon && coupon.type === 'success' ? coupon.code : 'failedCode',
      });
    }
  });

  onAction(setOrderComment.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(setDispatchType.toString(), async (action: Action<SetDispatchTypePayload>, getState, dispatch) => {
    const state = getState();
    const coupon = state.cart.coupon?.type === 'success' && state.cart.coupon.code;

    if (coupon) {
      const couponHashCode: string = sha1(coupon);

      const couponCharge = couponChargesSelector({
        ...state,
        cart: {
          ...state.cart,
          coupon: {
            type: 'success',
            code: coupon,
            token: '',
            couponHashCode,
          },
        },
      });

      if (couponCharge?.error) {
        dispatch(removeOrderCoupon());
      }
    }
  });

  onAction(
    addOrderCoupon.toString(),
    async (action: Action<AddOrderCouponPayload>, getState, dispatch, { flowAPI }) => {
      const { coupon } = action.payload;
      const couponHashCode: string = sha1(coupon);

      const state = getState();
      const supportedDispatchTypes = supportedDispatchTypesSelector(state);
      const isDineInEnabled = supportedDispatchTypes.has('dine-in');

      const couponCharge = couponChargesSelector({
        ...state,
        cart: {
          ...state.cart,
          coupon: {
            type: 'success',
            code: coupon,
            token: '',
            couponHashCode,
          },
        },
      });
      if (couponCharge?.error) {
        flowAPI.biLogger &&
          flowAPI.biLogger.cartPromoCodeApplyFailure({ errorReason: couponCharge.error.reason, promoCode: coupon });
        dispatch(
          addOrderCouponFailure({
            type: 'failure',
            message: getDisplayableCouponError(couponCharge.error, isDineInEnabled),
          }),
        );
        return;
      }

      const restaurantId = state.session.restaurant.id;

      try {
        const response = await fetch(
          `https://codeusages.wixrestaurants.com/v1/prefixes/${restaurantId}/codes/${coupon}/generateToken`,
          {
            method: 'POST',
          },
        );
        const { token } = await response.json();

        if (!token) {
          dispatch(addOrderCouponFailure({ type: 'failure', message: 'checkout_main_entercode_errormessage' }));
          return;
        }

        dispatch(addOrderCouponSuccess({ type: 'success', token, code: coupon, couponHashCode }));
        if (flowAPI.biLogger) {
          flowAPI.biLogger.cartPromoCodeApplySuccess({
            promoCode: coupon,
          });
        }
        dispatch(saveStateToSessionStorage());
      } catch (e) {
        dispatch(addOrderCouponFailure({ type: 'failure', message: 'checkout_main_entercode_errormessage' }));
      }
    },
  );
}
