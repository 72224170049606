import moment from 'moment-timezone';
import { Action, DisplayableMenu, getDisplayableMenu, Menu, Restaurant } from '@wix/restaurants-client-logic';
import { navigate } from '../session/session.actions';
import { RouteUrls } from '../../core/constants';
import { setOrderItems } from './cart.actions';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { Dispatch } from 'redux';

export function skipToCheckout({
  flowAPI,
  menu,
  restaurant,
  platform,
  dispatchTime,
  dispatch,
}: {
  flowAPI: ControllerFlowAPI;
  menu: Menu;
  restaurant: Restaurant;
  platform: string;
  dispatchTime: number;
  dispatch: Dispatch<Action<any>>;
}) {
  const displayableMenu = getDisplayableMenu(
    menu,
    restaurant.locale,
    restaurant.currency,
    moment(dispatchTime),
    platform,
    'delivery',
  );

  const itemIdsQuery = flowAPI.controllerConfig.wixCodeApi.location.query
    ? flowAPI.controllerConfig.wixCodeApi.location.query.testItemId
    : undefined;

  if (!itemIdsQuery) {
    return;
  }

  const items = getDisplayableMenuItems(displayableMenu, itemIdsQuery.split(','));

  if (items.length > 0) {
    dispatch(
      setOrderItems({
        orderItems: items.map((item) => ({
          count: 1,
          itemId: item.id,
          price: item.price || 0,
        })),
      }),
    );
    dispatch(navigate({ routeUrl: RouteUrls.CHECKOUT_FLOW }));
  }
}

function getDisplayableMenuItems(displayableMenu: DisplayableMenu, itemIds: string[]) {
  const arr = [];
  for (const menu of displayableMenu) {
    for (const section of menu.sections) {
      for (const item of section.items) {
        if (itemIds.indexOf(item.id) !== -1) {
          arr.push(item);
        }
      }
    }
  }

  return arr;
}
